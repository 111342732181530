<template>
  <div>
    <div class="df" style="background-color: #fff">
      <div>
        <el-upload action="#" :show-file-list="false" :http-request="addImg">
          <i
            class="el-icon-picture"
            style="
              color: #409eff;
              font-size: 24px;
              line-height: 40px;
              cursor: pointer;
            "
          ></i>
        </el-upload>
      </div>
      <div style="flex: 1; width: 0; padding: 0 20px">
        <el-select
          v-model="label"
          placeholder="请选择"
          style="width: 100%"
          @change="selectChange"
        >
          <el-option
            v-for="item in optionsList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div v-if="label === '自定义'" style="margin-top: 10px">
          <el-input
            type="textarea"
            autosize
            placeholder="请输入自定义内容"
            v-model="value"
          >
          </el-input>
        </div>
        <div style="width: 100%; flex-wrap: wrap" class="df">
          <template v-if="imgList.length">
            <div
              v-for="(v, i) in imgList"
              :key="'img' + i"
              class="pointer mask"
              style="width: 120px; margin: 0 5px"
            >
              <div
                style="width: 120px; height: 120px; margin: 10px"
                class="mask-container"
              >
                <div class="mask-box df aic jcc">
                  <i
                    class="el-icon-zoom-in pointer"
                    style="color: #fff; font-size: 28px"
                    @click="handlePreviewImage(v)"
                  ></i>
                  <div style="width: 30px; height: 30px"></div>
                  <i
                    class="el-icon-delete pointer"
                    style="color: #fff; font-size: 28px"
                    @click="handleImgRemove(v)"
                  ></i>
                </div>
                <el-image
                  :src="v"
                  style="width: 100%; height: 100%; object-fit: cover"
                >
                </el-image>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div>
        <el-button type="primary" @click="submit">提交</el-button>
      </div>
    </div>
    <div
      v-if="list.length"
      style="
        background-color: #f8f8f8;
        width: 100%;
        padding: 20px;
        margin-top: 20px;
        border-radius: 20px;
      "
    >
      <el-collapse v-model="activeNames" @change="handleChange" accordion>
        <div
          style="
            width: 100%;
            border-radius: 20px;
            background-color: #fff;
            margin-bottom: 20px;
            overflow: hidden;
          "
          v-for="item in list"
          :key="item.id"
        >
          <div class="card-head df jcb aic" style="padding: 20px 20px 10px">
            <div style="text-align: left">
              <div>
                {{ item.createdName }}
              </div>
              <div style="font-size: 14px; color: #909399">
                {{ item.createdTime.replace("T", " ") }}
              </div>
            </div>
            <div>
              <el-popover placement="bottom" width="100px" trigger="hover">
                <div
                  class="more-btn df aic jcc pointer"
                  style="color: #409eff"
                  @click="editCom(item)"
                >
                  修改
                </div>
                <div class="more-box" slot="reference">
                  <i class="el-icon-more"></i>
                </div>
              </el-popover>
            </div>
          </div>
          <div style="padding: 10px 20px 20px">
            <div style="text-align: left">
              <div v-if="!item.isEdit">{{ item.txtInfo }}</div>
              <el-input
                v-else
                type="textarea"
                placeholder="请输入内容"
                v-model="item.txtInfo"
              >
              </el-input>
            </div>
            <div
              class="df"
              style="flex-wrap: wrap; width: 100%"
              v-if="!item.isEdit"
            >
              <div
                class="pointer"
                style="
                  width: 120px;
                  height: 120px;
                  object-fit: cover;
                  margin: 10px;
                "
                v-for="(v, i) in JSON.parse(item.imgInfo)"
                :key="i"
              >
                <el-image
                  :src="v"
                  :preview-src-list="[v]"
                  style="width: 100%; height: 100%; object-fit: cover"
                >
                </el-image>
              </div>
            </div>
            <div v-else class="df" style="flex-wrap: wrap; width: 100%">
              <div
                v-for="(v, i) in JSON.parse(item.imgInfo)"
                :key="'img' + i"
                class="pointer mask"
                style="width: 120px; margin: 0 5px"
              >
                <div
                  style="width: 120px; height: 120px; margin: 10px"
                  class="mask-container"
                >
                  <div class="mask-box df aic jcc">
                    <i
                      class="el-icon-zoom-in pointer"
                      style="color: #fff; font-size: 28px"
                      @click="handlePreviewImage(v)"
                    ></i>
                    <div style="width: 30px; height: 30px"></div>
                    <i
                      class="el-icon-delete pointer"
                      style="color: #fff; font-size: 28px"
                      @click="handleImgRemove1(v, item)"
                    ></i>
                  </div>
                  <el-image
                    :src="v"
                    style="width: 100%; height: 100%; object-fit: cover"
                  >
                  </el-image>
                </div>
              </div>
              <div style="margin: 10px">
                <el-upload
                  action="#"
                  :show-file-list="false"
                  :http-request="(params) => addImg1(params, item)"
                >
                  <div
                    class="pointer df aic jcc"
                    style="
                      width: 120px;
                      height: 120px;
                      margin: 0 5px;
                      border: 1px dashed #d9d9d9;
                      border-radius: 6px;
                    "
                  >
                    <i
                      class="el-icon-plus"
                      style="
                        font-size: 28px;
                        color: #8c939d;
                        width: 178px;
                        height: 178px;
                        line-height: 178px;
                        text-align: center;
                      "
                    ></i>
                  </div>
                </el-upload>
              </div>
            </div>
            <div
              v-if="item.isEdit"
              style="text-align: right; padding-top: 10px"
            >
              <el-button type="primary" size="mini" @click="editItem(item)"
                >修改</el-button
              >
            </div>
          </div>

          <div class="card-foot">
            <el-collapse-item
              :title="'回复:' + item.count + '条'"
              :name="item.id"
              v-if="item.count"
            >
              <div
                style="width: 100%; background-color: #ebeff1; padding: 10px"
                v-loading="loading"
              >
                <div
                  v-for="cItem in commentList"
                  :key="cItem.id"
                  style="
                    border-radius: 20px;
                    background-color: #fff;
                    margin-bottom: 20px;
                  "
                >
                  <div
                    class="card-head"
                    style="text-align: left; padding: 10px"
                  >
                    {{ cItem.createdName }}
                  </div>
                  <div>
                    <div style="text-align: left; padding: 10px 10px 0">
                      <div>{{ cItem.txtInfo }}</div>
                    </div>
                    <div
                      class="df"
                      style="flex-wrap: wrap; width: 100%; padding: 10px"
                    >
                      <div
                        class="pointer"
                        style="
                          width: 120px;
                          height: 120px;
                          object-fit: cover;
                          margin: 10px;
                        "
                        v-for="(v, i) in JSON.parse(cItem.imgInfo)"
                        :key="i"
                      >
                        <el-image
                          :src="v"
                          :preview-src-list="[v]"
                          style="width: 100%; height: 100%; object-fit: cover"
                        >
                        </el-image>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </div>
        </div>
      </el-collapse>

      <div class="df" style="justify-content: flex-end; padding: 20px 0">
        <el-pagination
          background
          :page-size="query.limit"
          layout="total,prev, pager, next"
          :total="total"
          :current-page.sync="query.page"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
    </div>
    <div style="width: 100%; height: 600px" class="df aic jcc" v-else>
      <el-empty description="暂无商户通报记录"></el-empty>
    </div>
    <el-image-viewer
      style="z-index: 9999"
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="previewUrl"
    />
  </div>
</template>

<script>
import { local } from "@/util/util";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

import axios from "@/util/api";
import api from "@/util/extra-api";
export default {
  components: {
    ElImageViewer,
  },
  props: {
    optionsList: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    id: {
      type: Number,
      required: true,
      default: null,
    },
    feedbackType: {
      type: Number,
      required: true,
      default: null,
    },
    companyId: {
      type: String,
      required: true,
      default: null,
    },
    url: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      value: "",
      label: "",
      imgList: [],
      previewUrl: [],
      showViewer: false,
      query: {
        page: 1,
        keyword: "",
        limit: 5,
      },
      total: 0,
      activeNames: null,
      loading: false,
      commentList: [],
      list: [],
      oldData: null,
    };
  },
  created() {
    this.getList();
    if (this.url) {
      // console.log(this.url,'传入');
      this.imgList.push(this.url)
    }
  },
  watch:{
    url(newVal){
      if(newVal){
      // console.log(this.url,'改变');
      this.imgList.push(newVal)
      }
    }
  },
  methods: {
    selectChange(data) {
      console.log(data);
      if (data !== "自定义") {
        this.value = data;
      }else{
        this.value = "";
      }
    },
    handleChange(val) {
      // console.log(val);
      if (val) {
        this.loading = true;
        api
          .get("/v1/pc/sw/replyList", {
            page: 1,
            limit: 100,
            parentsId: val,
          })
          .then((res) => {
            this.commentList = res.data;
            this.loading = false;
          });
      }
    },
    editItem(data) {
      if (!data.txtInfo) {
        this.$message({
          message: "请填写反馈内容",
          type: "warning",
        });
        return;
      }
      api
        .post("/v1/pc/sw/updateRemarkOn", {
          ccpId: this.id,
          txtInfo: data.txtInfo,
          type: this.feedbackType,
          companyId: this.companyId,
          imgInfo: data.imgInfo,
          id: data.id,
        })
        .then((res) => {
          if (res.code === 200) {
            this.getList();
            this.oldData = null;
          }
        });
    },
    editCom(data) {
      if (this.oldData) {
        let index = null;
        this.list.forEach((v, i) => {
          if (v.id === JSON.parse(this.oldData).id) {
            index = i;
          }
        });
        this.$set(this.list, index, JSON.parse(this.oldData));
      }

      this.oldData = JSON.stringify(data);
      this.$set(data, "isEdit", true);
    },
    pageChange(page) {
      this.query.page = page;
      this.getList();
    },
    getList() {
      api
        .get("/v1/pc/sw/remarkOnList", {
          ...this.query,
          type: this.feedbackType,
          submitId: this.id,
        })
        .then((res) => {
          if (res.code === 200) {
            console.log(res.data, 123);
            this.list = res.data.map((v) => {
              return {
                ...v,
                isEdit: false,
              };
            });
            this.total = res.total;
          }
        });
    },
    handlePreviewImage(url) {
      this.previewUrl = [url];
      this.showViewer = true;
    },
    closeViewer() {
      this.showViewer = false;
      this.previewUrl = [];
    },
    handleImgRemove(url) {
      this.imgList = this.imgList.filter((v) => v !== url);
    },
    handleImgRemove1(url, data) {
      // console.log(data.imgInfo);
      // console.log(JSON.parse(data.imgInfo).filter((v) => v !== url));
      this.$set(
        data,
        "imgInfo",
        JSON.stringify(JSON.parse(data.imgInfo).filter((v) => v !== url))
      );
    },
    addImg1(params, data) {
      const file = params.file,
        fileType = file.type,
        isImage = fileType.indexOf("image") != -1,
        isLt10M = file.size / 1024 / 1024 < 10;
      // 这里常规检验，看项目需求而定
      if (!isImage) {
        this.$message.error("只能上传图片格式png、jpg、gif!");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("只能上传图片大小小于10M");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", 1117);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("图片上传失败，请重新上传");
        } else {
          this.$set(
            data,
            "imgInfo",
            JSON.stringify(JSON.parse(data.imgInfo).concat([resp.url]))
          );
        }
      });
    },
    addImg(params) {
      const file = params.file,
        fileType = file.type,
        isImage = fileType.indexOf("image") != -1,
        isLt10M = file.size / 1024 / 1024 < 10;
      console.log(file, 123);
      // 这里常规检验，看项目需求而定
      if (!isImage) {
        this.$message.error("只能上传图片格式png、jpg、gif!");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("只能上传图片大小小于10M");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", 1117);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("图片上传失败，请重新上传");
        } else {
          this.imgList.push(resp.url);
        }
      });
    },
    submit() {
      if (!this.value) {
        this.$message({
          message: "请填写反馈内容",
          type: "warning",
        });
      } else {
        api
          .post("/v1/pc/sw/remarkOn", {
            ccpId: this.id,
            txtInfo: this.value,
            type: this.feedbackType,
            companyId: this.companyId,
            imgInfo: JSON.stringify(this.imgList),
          })
          .then((res) => {
            if (res.code === 200) {
              this.value = "";
              this.imgList = [];
              this.query.page = 1;
              this.getList();
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mask-box {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
}
.mask-container:hover .mask-box {
  display: flex;
}
.mask-container {
  position: relative;
}
.pointer {
  cursor: pointer;
}
.more-btn {
  width: 100%;
  padding: 5px 10px;
  // border-radius: 8px;
}
.more-btn:hover {
  background-color: #ededed;
}
.jcb {
  justify-content: space-between;
}
.card-head {
  border-bottom: 1px solid #e7e6e4;
}
.card-foot {
  // border-top: 1px solid #e7e6e4;
}
/deep/ .el-collapse {
  border: none !important;
}
/deep/ .el-collapse-item__content {
  padding: 0 !important;
}
</style>